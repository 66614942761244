import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { navigate } from "gatsby";
import { Snackbar, Alert } from "@mui/material";
import icon from "../../images/icon.png";
import {
  logOut,
  addSubProject,
  login,
  setMeasures,
  setProjectData,
  toggleShowSnackBar,
  setLoading,
} from "../../store/ducks/general";

const style = {
  image: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
  alert: {
    backgroundColor: "#000",
    color: "#fff",
    fontFamily: "sans-serif",
    border: ".5px solid rgba(255,255,255,0.25)",
  },
};

const UserRoute = ({ children }) => {
  const dispatch = useDispatch();
  const loginValues = useSelector((state) => state.general.loginValues);
  const showSnackBar = useSelector((state) => state.general.showSnackBar);
  const snackBarMsg = useSelector((state) => state.general.snackBarMsg);

  if (loginValues) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${loginValues.token}`;
  }

  axios.interceptors.response.use(
    function (response) {
      // Do something before request is sent
      return response;
    },
    function (error) {
      // Do something with request error
      let res = error.response;
      if (
        res?.status === 401 &&
        res?.config &&
        !res?.config?.__isRetryRequest
      ) {
        dispatch(logOut());
        localStorage.removeItem("auth");
        localStorage.removeItem("subProjectData");
        localStorage.removeItem("subProjects");
        localStorage.removeItem("subMenu");
        localStorage.removeItem("ally-supports-cache");
        navigate("/");
      }
    }
  );

  useEffect(() => {
    if (loginValues) {
      if (loginValues.userId == null || loginValues.subProject == null) {
        const dataUser = JSON.parse(window.localStorage.getItem("auth"));
        const general = JSON.parse(window.localStorage.getItem("general"));
        const subProjectData = JSON.parse(
          window.localStorage.getItem("subProjectData")
        );
        dispatch(login(dataUser));
        if (dataUser?.subProject) {
          dispatch(addSubProject(dataUser.subProject));
        }
        if (general?.isMetricOrUs) {
          dispatch(setMeasures(general));
        }
        if (subProjectData) {
          dispatch(
            setProjectData(
              JSON.parse(window.localStorage.getItem("subProjectData"))
            )
          );
        }
      }
    }

    if (loginValues && loginValues.token) {
      getCurrentUser();
    }
  }, [loginValues?.userId]);

  const getCurrentUser = async () => {
    try {
      const { data } = await axios.get(`/current-user`);
      if (!data.userExists) {
        throw "Error Current User";
      }
    } catch (err) {
      dispatch(logOut());
      localStorage.removeItem("auth");
      localStorage.removeItem("subProjectData");
      localStorage.removeItem("subProjects");
      localStorage.removeItem("subMenu");
      localStorage.removeItem("ally-supports-cache");
      navigate("/");
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSnackBar}
        autoHideDuration={60000000}
        onClose={() => {
          dispatch(toggleShowSnackBar());
          dispatch(setLoading(false));
        }}
      >
        <Alert
          icon={false}
          onClose={() => {
            dispatch(toggleShowSnackBar());
            dispatch(setLoading(false));
          }}
          style={style.alert}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            <img src={icon} style={style.image} />
            {snackBarMsg}
          </span>
        </Alert>
      </Snackbar>
      {children}
    </>
  );
};

export default UserRoute;
