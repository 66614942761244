import React from "react";
import styled from "styled-components";

const Wrapper = styled.section`
  padding: 2rem 3rem;
  @media (max-width: ${props => props.theme.breakPoints.sm}){
    padding:2rem 1.5rem;
  }
`;

const StandardContainer = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
export default StandardContainer;
